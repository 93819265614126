// import { createApp } from 'vue'
import Projects from "../vue/Projects.vue";
import Paginate from "vue-paginate";
import Vue from "vue";
import Swiper, { Navigation } from 'swiper';
import MmenuLight from  '../../node_modules/mmenu-light/src/mmenu-light.js';

Swiper.use([Navigation]);

// Intiate Vuejs instance if we find #appVue (just in Projects page)
const appVue = document.getElementById( "appVue" );
if (appVue) {
    Vue.use(Paginate)

    var app = new Vue({
        el: '#appVue',
        delimiters: ["${","}"],
        components: {
            Projects
        }
    });
}

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {


    // Mobile menu init // Sync search and menu open
    const menu = new MmenuLight( document.querySelector( "#mobile-menu" ), "(max-width: 1024px)" );
    const navigator = menu.navigation();
    const drawer = menu.offcanvas();

    // Open Menu
    const navTrigger = document.querySelector('.js-open-nav');
    const projectWrapper = document.getElementById('app');

    // Toggle Menu
    bindEvent(navTrigger, "click", function() {
        this.classList.toggle('is-active');
        projectWrapper.classList.toggle('js-is-open');

        if (this.classList.contains('is-active')) {
            drawer.open();
        } else {
            drawer.close();
        }

    });

    // Accordions
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = this.closest('[data-accordion-link]');
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }


    // Swiper

    const swiper = new Swiper('.swiper-container', {
        loop: true,
        spaceBetween: 10,
        allowTouchMove: false,
    
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });


    const specToggle = document.querySelector('.js-toggle-specs');
    const specWrap = document.querySelector('.product-specs__wrapper');
    const specClose = document.querySelector('.js-close-specs');

    const featureOpen = document.querySelector('.js-open-feature');
    const featureList = document.querySelector('.product-specs__ul');

    if (featureList) {
        featureOpen.addEventListener('click',  function(e) { 
            e.preventDefault();

            featureList.classList.add('opened');
            this.classList.add('hidden');

        }, false);
    }

    if (specToggle) {
        specToggle.addEventListener('click',  function(e) { 
            e.preventDefault();

            specWrap.classList.toggle('opened');
            this.classList.toggle('open');

            if(this.innerHTML == "View product specifications") {
                this.innerHTML = "Hide product specifications"
            } else if (this.innerHTML == "Hide product specifications") {
                specToggle.innerHTML = "View product specifications"
            }

        }, false);

        specClose.addEventListener('click',  function(e) { 
            e.preventDefault();

            specWrap.classList.toggle('opened');
            specToggle.classList.toggle('open');

            if(specToggle.innerHTML == "View product specifications") {

                specToggle.innerHTML = "Hide product specifications"
            } else if (specToggle.innerHTML == "Hide product specifications") {
                specToggle.innerHTML = "View product specifications"
            }
        }, false);
    }


    // product filters
    const filterForm = document.querySelector('.js-filter-form');
    const productResults = document.querySelector('.js-product-results');

    function filterProducts() {
        const formData = new FormData(filterForm);
        const data = [...formData.entries()];
        const query = data
            .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
            .join('&');
        const action = filterForm.getAttribute('action') + '?' + query;


        productResults.innerHTML = '<p class="text-center">Loading...</p>';

        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState === 4 && this.status === 200) {
                const el = document.createElement( 'html' );
                el.innerHTML = xhttp.responseText;
                const results = el.querySelector('.js-product-results');
                productResults.innerHTML = results.innerHTML;
            }
        };
        xhttp.open("GET", action, true);
        xhttp.send();

    }


    if (filterForm) {
        const inputs = filterForm.querySelectorAll('input');
        for (const input of inputs) {
            bindEvent(input, 'change', filterProducts)
        }
    }


    // FORMS
    const forms = document.querySelectorAll('form[data-ajax]');
    if (forms.length) {
        for (const form of forms) {

            form.addEventListener('freeform-ajax-success', (e) => {
                const formSubmitted = e.form;
                formSubmitted.classList.add('success');
                window.setTimeout(() => {
                    formSubmitted.scrollIntoView({ behavior: 'smooth', block: 'start'});
                }, 100);

            });

            // Override the way field errors are displayed
            form.addEventListener('freeform-render-field-errors', function(event) {
                // Prevent the default behaviour
                event.preventDefault();

                const errors = event.errors;
                for (const key in errors) {
                    if (!errors.hasOwnProperty(key) || !key) {
                        continue;
                    }

                    const messages = errors[key];
                    const errorsList = document.createElement("p");
                    errorsList.classList.add("ff-errors");
                    errorsList.classList.add("error");
                    errorsList.classList.add("text-error");
                    errorsList.classList.add("font-sansBold");
                    errorsList.classList.add("mt-2");
                    errorsList.innerHTML = messages.join('<br>');

                    const inputList = form.querySelectorAll("*[name=" + key + "], *[name='" + key + "[]']");
                    for (let inputIndex = 0; inputIndex < inputList.length; inputIndex++) {
                        const input = inputList[inputIndex];
                        input.classList.add("this-field-has-errors");
                        input.parentElement.appendChild(errorsList);
                    }
                }
            });
        }
    }

    //<p class="error text-error font-sansBold mt-2">Please enter your name</p>


    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})
